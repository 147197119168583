/*
 *  Owl Carousel - Core
 */
.owl-carousel {
	display: none;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
	/* position relative and z-index fix webkit rendering fonts issue */
	position: relative;
	z-index: 1;

	.owl-stage {
		position: relative;
		-ms-touch-action: pan-Y;
		touch-action: manipulation;
		-moz-backface-visibility: hidden; /* fix firefox animation glitch */
	}

	.owl-stage:after {
		content: ".";
		display: block;
		clear: both;
		visibility: hidden;
		line-height: 0;
		height: 0;
	}

	.owl-stage-outer {
		position: relative;
		overflow: hidden;
		/* fix for flashing background */
		-webkit-transform: translate3d(0px, 0px, 0px);
	}

	.owl-wrapper,
	.owl-item{
		-webkit-backface-visibility: hidden;
		-moz-backface-visibility: hidden;
		-ms-backface-visibility: hidden;
		-webkit-transform: translate3d(0,0,0);
		-moz-transform: translate3d(0,0,0);
		-ms-transform: translate3d(0,0,0);
	}

	.owl-item {
		position: relative;
		min-height: 1px;
		float: left;
		-webkit-backface-visibility: hidden;
		-webkit-tap-highlight-color: transparent;
		-webkit-touch-callout: none;
	}
	.owl-item img {
		display: block;
		width: 100%;
	}

	.owl-nav.disabled,
	.owl-dots.disabled {
		display: none;
	}

	.owl-nav .owl-prev,
	.owl-nav .owl-next,
	.owl-dot {
		cursor: pointer;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.owl-nav button.owl-prev,
	.owl-nav button.owl-next,
	button.owl-dot {
		background: none;
		color: inherit;
		border: none;
		padding:0!important;
		font: inherit;
	}

	&.owl-loaded {
		display: block;
	}

	&.owl-loading {
		opacity: 0;
		display: block;
	}

	&.owl-hidden {
		opacity: 0;
	}

	&.owl-refresh .owl-item {
		visibility: hidden;
	}

	&.owl-drag .owl-item {
		touch-action: pan-y;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	&.owl-grab {
		cursor: move;
		cursor: grab;
	}

	&.owl-rtl {
		direction: rtl;
	}

	&.owl-rtl .owl-item {
		float: right;
	}
}

.owl-lazy-content {
	
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 2rem;
    align-items: center;
    justify-content: flex-start;
    max-width: 1200px;
    margin: 0 auto;

    	@include media-breakpoint-up(lg) {
			display: flex;
	    }

    	&.right {
    		justify-content: flex-end;
    	}

    	&.center {
    		justify-content: center;
    	}

    
}

.owl-lazy-inner {
    text-align: center;
	
	& h1,
	& h2,
	& h3,
	& h4,
	& h5,
	& h6,
    & p {
    	color: #4d4d4d;
    	margin-bottom: 0;
    }

    & h1,
	& h2,
	& h3,
	& h4,
	& h5,
	& h6 {
		font-size: 1.65rem;
	}

	& a {
		color: #4d4d4d;
		font-size: 1vw;
	    text-transform: uppercase;
	    letter-spacing: 1px;
		position: relative;

		&:after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			bottom: -5px;
			height: 1px;
    		background: #4d4d4d;
		}
	}

    @include media-breakpoint-up(lg) {
		flex: 1;
    	max-width: 33%;
    }

    @include media-breakpoint-up(md) {
		& p {
		   	margin-bottom: 1rem;
		}

		& h1,
		& h2,
		& h3,
		& h4,
		& h5,
		& h6 {
			font-size: 2rem;
			margin-bottom: 0.5rem;
		}
    }
}


/* No Js */
.no-js .owl-carousel {
	display: block;
}


.owl-lazy-content {
	position: absolute;
}

