header {
	&.banner {
		position: relative;

		&.sticky {
			position: fixed;
			z-index: 999;
			top: 0;
			left: 0;
			right: 0;
			padding: 0;
			background: #fff;
			transition: all 0.15s ease;

			& .container {
				max-width: 100%;
					& li {
						& a {
							line-height: 62px;
						}
					}
			}

			& .menu-btn {
				padding: 0;
				margin-top: -17px
			}

			& .search-kassatex {
				top: 16px;
				display: none!important;
			}

			& .search-input {
				display: none;
			}

			& .search-header {
				top: 3rem;
			}

			& .search-form {
				display: none!important;
			}

			& .header-top {

			    @include media-breakpoint-up(lg) {
			    	max-width: 200px;
			    	float: left;
			    	padding: 0.5rem 0 0.5rem 1rem!important;
				}
			}

			& .float-right {
				float: none!important;
			}

			& .nav-primary {
				margin-top: 0;
				padding-left: 275px;

					& .menu,
					& .nav {
						margin: 0;
					}

					& .menu-header-container {
						justify-content: flex-start;
						transition: all 0.25s;
					}
			}

		}

		.header-top {

			position: relative;

			& .site-title {
				margin-bottom: 0!important;
			}
		}
	}

	& .site-title-img {
		max-width: 300px;
	}

	& .nav-primary {
			margin: 1rem auto 0;
	    position: relative;
	    display: none;

			& .nav,
			& .menu {
					display: flex;
			    padding-left: 0;
			    margin-bottom: 0;
			    list-style: none;
					text-transform: uppercase;
					max-width: 980px;
			    width: 100%;
					margin: 0 auto;
			    justify-content: space-around;

					& .search-kassatex {
						position: absolute;
						right: 0;
					}

					& ul {
						margin-bottom: 0;
						padding: 0;
						display: flex;
    			  justify-content: space-evenly;
					}

					& li {
						margin: 0 1rem;
						font-size: 0.9rem;
						display: inline-block;

						& a {
							font-weight: 300;
							letter-spacing: 0.65px;
						}

						&:hover a,
						&:active a,
						& .current-page {
							position: relative;
							color: #000;
						}
					}

					& li:first-child {
						// margin-left: 0;
					}
			}


			& .menu-header-container {
				display: flex;
			    align-items: center;
			    justify-content: center;
			    transition: all 0.25s;
			}

		@include media-breakpoint-up(lg) {
			display: block!important;
		}

		@include media-breakpoint-up(lg) {

		}
	}

	& .search-kassatex {
			top: 20px;
			right: 0px;
			width: auto;
	    height: 35px;
	    position: absolute;
	    overflow: hidden;
	    cursor: pointer;
	    text-align: right;

	    	& span {
	    		display: none;
			    float: left;
			    line-height: 28px;
			    text-transform: uppercase;
			    letter-spacing: 1px;
			    font-size: 14px;

			    @include media-breakpoint-up(md) {
						display: inline-block;
			    }
	    	}

				& ul {
					margin-left: 15px;
    			margin-top: 5px;
					display: none;

					@include media-breakpoint-up(lg) {
						display: inline-block;
					}


						& li {
							margin: 0 3px;

							& a {
									text-align: center;
									& i {
										margin: 0 auto;
									}
							}
						}
				}

				& .kassa {
					font-size: 17px;
				}

				& .fa-instagram {
					width: 16px;
					height: 16px;
			    background-size: contain;
			    background-repeat: no-repeat;
			    background-position: 10% 100%;
				}
	}

	& .search-header {
		top: 60px;
		position: absolute;
		right: 0;
		display: none;
		z-index: 99999999;

			& .search-field {
				border: 1px solid #616161;
			    margin-bottom: 8px;
			    padding: 12px 8px 10px;
			    font-size: 12px;
			    width: 100%;
			    min-width: 150px;
			    border-radius: 0!important;
			}

			& .search-submit {
				display: none;
			}

			&.visible-search {
				display: block;
			}
	}
}

.pushy-content .search-input {
	display: none;

	@include media-breakpoint-up(lg) {
		display: block;
	}
}

.pushy-open-left {
    overflow: hidden!important;
}


#menu-header {
	& li {
		width: 100%;

		@include media-breakpoint-up(lg) {
			width: auto!important;
		}
	}
}


.menu-btn {
    border: 0;
    top: 50%;
    left: 1rem;
    font-size: 24px;
    margin-top: -30px;
    display: block;
    position: absolute;
		padding: 0;
    background: transparent;
    outline: none!important;

    @include media-breakpoint-up(lg) {
		display: none!important;
	}
}


.main-content-header {
	@include media-breakpoint-up(lg) {
		top: 0;
		bottom: 0;
		position: absolute;
		display: flex;
		max-width: 55%;

			& h2.entry-title.leading-title {
				font-size: 3.5rem;
				line-height: 1;
				margin-top: 2rem;
				text-transform: uppercase;
			}

			&.top-left {
				& > div {
					margin: 0;
					align-items: flex-start!important;
				}
			}

			&.center-left {
				& > div {
					margin: 0;
					align-items: center!important;
				}
			}

			&.bottom-left {
				& > div {
					margin: 0;
					align-items: flex-end!important;
				}
			}


			&.top-right {
				right: 0;
				& > div {
					margin: 0;
					align-items: flex-start!important;
				}
			}

			&.center-right {
				right: 0;
				& > div {
					margin: 0;
					align-items: center!important;
				}
			}

			&.bottom-right {
				right: 0;
				& > div {
					margin: 0;
					align-items: flex-end!important;
				}
			}



			&.top-center {
				left: 0;
				right: 0;
				margin: 0 auto;

				& > div {
					margin: 0 auto;
					align-items: flex-start!important;
				}
			}

			&.center-center {
				left: 0;
				right: 0;
				margin: 0 auto;

				& > div {
					margin: 0 auto;
					align-items: center!important;
				}
			}

			&.bottom-center {
				left: 0;
				right: 0;
				margin: 0 auto;

				& > div {
					margin: 0 auto;
					align-items: flex-end!important;
				}
			}
	}
}
