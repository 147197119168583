
// @font-face {
//   font-family: 'Aspect';
//   src: url($fonts-path + 'aspect.eot?#iefix') format('embedded-opentype'),
//        url($fonts-path + 'aspect.woff') format('woff'),
//        url($fonts-path + 'aspect.ttf')  format('truetype'),
//        url($fonts-path + 'aspect.svg#Aspect') format('svg');
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Aspect Bold';
//   src: url($fonts-path + 'aspect-bold.eot?#iefix') format('embedded-opentype'),
//        url($fonts-path + 'aspect-bold.woff') format('woff'),
//        url($fonts-path + 'aspect-bold.ttf')  format('truetype'),
//        url($fonts-path + 'aspect-bold.svg#Aspect-Bold') format('svg');
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Aspect Light';
//   src: url($fonts-path + 'aspect-light.eot?#iefix') format('embedded-opentype'),
//        url($fonts-path + 'aspect-light.woff') format('woff'),
//        url($fonts-path + 'aspect-light.ttf')  format('truetype'),
//        url($fonts-path + 'aspect-light.svg#Aspect-Light') format('svg');
//   font-weight: normal;
//   font-style: normal;
// }


// @font-face {
//   font-family: 'Effra Light';
//   src: url($fonts-path + '9789.eot?#iefix') format('embedded-opentype'),
//        url($fonts-path + '9789.woff') format('woff'),
//        url($fonts-path + '9789.ttf')  format('truetype'),
//        url($fonts-path + '9789.svg#Aspect-Light') format('svg');
//   font-weight: 300;
//   font-style: normal;
// }


// @font-face {
//   font-family: 'Effra Regular';
//   src: url($fonts-path + '9785.eot?#iefix') format('embedded-opentype'),
//        url($fonts-path + '9785.woff') format('woff'),
//        url($fonts-path + '9785.ttf')  format('truetype'),
//        url($fonts-path + '9785.svg#Aspect-Light') format('svg');
//   font-weight: 400;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Effra Regular Italic';
//   src: url($fonts-path + '9786.eot?#iefix') format('embedded-opentype'),
//        url($fonts-path + '9786.woff') format('woff'),
//        url($fonts-path + '9786.ttf')  format('truetype'),
//        url($fonts-path + '9786.svg#Aspect-Light') format('svg');
//   font-weight: 400;
//   font-style: italic;
// }


// @font-face {
//   font-family: 'Effra Bold';
//   src: url($fonts-path + '9787.eot?#iefix') format('embedded-opentype'),
//        url($fonts-path + '9787.woff') format('woff'),
//        url($fonts-path + '9787.ttf')  format('truetype'),
//        url($fonts-path + '9787.svg#Aspect-Light') format('svg');
//   font-weight: 800;
//   font-style: normal;
// }


@font-face {
  font-family: 'kassatex';
  src: url($fonts-path + 'kassatex.eot?l34npy');
  src: url($fonts-path + 'kassatex.eot?l34npy#iefix') format('embedded-opentype'),
      url($fonts-path + 'kassatex.ttf?l34npy') format('truetype'),
      url($fonts-path + 'kassatex.woff?l34npy') format('woff'),
      url($fonts-path + 'kassatex.svg?l34npy#kassatex') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Vanitas';
  src: url($fonts-path + 'Vanitas/3898A3_0_0.eot?#iefix') format('embedded-opentype'),
       url($fonts-path + 'Vanitas/3898A3_0_0.woff') format('woff'),
       url($fonts-path + 'Vanitas/3898A3_0_0.ttf')  format('truetype'),
       url($fonts-path + 'Vanitas/3898A3_0_0.svg#Aspect-Light') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Vanitas';
  src: url($fonts-path + 'Vanitas/3898A3_1_0.eot?#iefix') format('embedded-opentype'),
       url($fonts-path + 'Vanitas/3898A3_1_0.woff') format('woff'),
       url($fonts-path + 'Vanitas/3898A3_1_0.ttf')  format('truetype'),
       url($fonts-path + 'Vanitas/3898A3_1_0.svg#Aspect-Light') format('svg');
  font-weight: 700;
  font-style: normal;
}


// @font-face{
//   font-family:'Avenir LT W01_65 Medium1475532';
//   src:url($fonts-path + 'Avenir/' + 'e0542193-b2e6-4273-bc45-39d4dfd3c15b.eot?#iefix');
//   src:url($fonts-path + 'Avenir/' + 'e0542193-b2e6-4273-bc45-39d4dfd3c15b.eot?#iefix') format('eot'),
//   url($fonts-path + '17b90ef5-b63f-457b-a981-503bb7afe3c0.woff2') format('woff2'),
//   url($fonts-path + 'c9aeeabd-dd65-491d-b4be-3e0db9ae47a0.woff') format('woff'),
//   url($fonts-path + '25f994de-d13f-4a5d-a82b-bf925a1e054d.ttf') format('truetype'),
//   url($fonts-path + '3604edbd-784e-4ca7-b978-18836469c62d.svg#3604edbd-784e-4ca7-b978-18836469c62d') format('svg');
// }

// @font-face{
//   font-family:'Avenir LT W01_35 Light1475496';
//   src:url($fonts-path + 'edefe737-dc78-4aa3-ad03-3c6f908330ed.eot?#iefix');
//   src:url($fonts-path + 'edefe737-dc78-4aa3-ad03-3c6f908330ed.eot?#iefix') format('eot'),
//     url($fonts-path + '0078f486-8e52-42c0-ad81-3c8d3d43f48e.woff2') format('woff2'),
//     url($fonts-path + '908c4810-64db-4b46-bb8e-823eb41f68c0.woff') format('woff'),
//     url($fonts-path + '4577388c-510f-4366-addb-8b663bcc762a.ttf') format('truetype'),
//     url($fonts-path + 'b0268c31-e450-4159-bfea-e0d20e2b5c0c.svg#b0268c31-e450-4159-bfea-e0d20e2b5c0c') format('svg');
// }
// @font-face{
//   font-family:'Avenir LT W01_35 Light_1475502';
//   src:url($fonts-path + '63a166cb-be60-435c-a056-75172e43efa5.eot?#iefix');
//   src:url($fonts-path + '63a166cb-be60-435c-a056-75172e43efa5.eot?#iefix') format('eot'),
//     url($fonts-path + 'a59168c1-917d-4de9-a244-0316c057c357.woff2') format('woff2'),
//     url($fonts-path + '6dc0e7d8-9284-44e1-8f05-984a41daa3a4.woff') format('woff'),
//     url($fonts-path + '2315df7a-8bc2-433d-bf0a-162fc0063de0.ttf') format('truetype'),
//     url($fonts-path + '7b1e5b2a-b6a6-43da-9021-ada6d1d797f2.svg#7b1e5b2a-b6a6-43da-9021-ada6d1d797f2') format('svg');
// }
// @font-face{
//   font-family:'Avenir LT W01_45 Book1475508';
//   src:url($fonts-path + '710789a0-1557-48a1-8cec-03d52d663d74.eot?#iefix');
//   src:url($fonts-path + '710789a0-1557-48a1-8cec-03d52d663d74.eot?#iefix') format('eot'),
//     url($fonts-path + '065a6b14-b2cc-446e-9428-271c570df0d9.woff2') format('woff2'),
//     url($fonts-path + '65d75eb0-2601-4da5-a9a4-9ee67a470a59.woff') format('woff'),
//     url($fonts-path + 'c70e90bc-3c94-41dc-bf14-caa727c76301.ttf') format('truetype'),
//     url($fonts-path + '0979215b-3a1b-4356-9c76-e90fa4551f1d.svg#0979215b-3a1b-4356-9c76-e90fa4551f1d') format('svg');
// }
// @font-face{
//   font-family:'Avenir LT W01_45 Book O1475514';
//   src:url($fonts-path + 'd8c50fcc-bc32-4f31-8c24-b170c64e2254.eot?#iefix');
//   src:url($fonts-path + 'd8c50fcc-bc32-4f31-8c24-b170c64e2254.eot?#iefix') format('eot'),
//     url($fonts-path + '476612d9-282d-4f76-95cd-b4dd31e7ed21.woff2') format('woff2'),
//     url($fonts-path + 'f1ebae2b-5296-4244-8771-5f40e60a564a.woff') format('woff'),
//     url($fonts-path + '04d1bf6c-070d-4b7e-9498-6051c9f0c349.ttf') format('truetype'),
//     url($fonts-path + 'd0a3e813-1257-4b66-812a-36101faa0fb3.svg#d0a3e813-1257-4b66-812a-36101faa0fb3') format('svg');
// }
// @font-face{
//   font-family:'Avenir LT W01_55 Roman1475520';
//   src:url($fonts-path + '57bf7902-79ee-4b31-a327-1bbf59a3d155.eot?#iefix');
//   src:url($fonts-path + '57bf7902-79ee-4b31-a327-1bbf59a3d155.eot?#iefix') format('eot'),
//     url($fonts-path + 'b290e775-e0f9-4980-914b-a4c32a5e3e36.woff2') format('woff2'),
//     url($fonts-path + '4b978f72-bb48-46c3-909a-2a8cd2f8819c.woff') format('woff'),
//     url($fonts-path + '9bdf0737-f98c-477a-9365-ffc41b9d1285.ttf') format('truetype'),
//     url($fonts-path + '15281d0d-e3c2-46e1-94db-cb681e00bfaa.svg#15281d0d-e3c2-46e1-94db-cb681e00bfaa') format('svg');
// }
// @font-face{
//   font-family:'Avenir LT W01_55 Obliqu1475526';
//   src:url($fonts-path + '1e9b2738-9316-4b0c-97c0-5423b69ea147.eot?#iefix');
//   src:url($fonts-path + '1e9b2738-9316-4b0c-97c0-5423b69ea147.eot?#iefix') format('eot'),
//     url($fonts-path + '1a7173fa-062b-49ad-9915-bc57d3bfc1f5.woff2') format('woff2'),
//     url($fonts-path + 'cdda031e-26e9-4269-83d1-5a218caa10db.woff') format('woff'),
//     url($fonts-path + '6d1ce413-19e7-4b43-b328-6cdedc58b35a.ttf') format('truetype'),
//     url($fonts-path + '33fac8ae-e7a5-4903-9ac1-c09a59b4c61d.svg#33fac8ae-e7a5-4903-9ac1-c09a59b4c61d') format('svg');
// }

// @font-face{
//   font-family:'Avenir LT W01_65 Medium1475538';
//   src:url($fonts-path + 'cebcf56a-ea7e-4f5c-8110-a7ee367c1f91.eot?#iefix');
//   src:url($fonts-path + 'cebcf56a-ea7e-4f5c-8110-a7ee367c1f91.eot?#iefix') format('eot'),
//     url($fonts-path + 'deb5e718-7abb-4df3-9365-edfa95317090.woff2') format('woff2'),
//     url($fonts-path + '04801919-17ee-4c6b-8b17-eb1965cb3ed6.woff') format('woff'),
//     url($fonts-path + 'cee053ec-4b41-4506-a1fe-796261690610.ttf') format('truetype'),
//     url($fonts-path + '38a556e4-b6e5-48c6-aece-b1d470af124e.svg#38a556e4-b6e5-48c6-aece-b1d470af124e') format('svg');
// }
// @font-face{
//   font-family:'Avenir LT W01_85 Heavy1475544';
//   src:url($fonts-path + '6af9989e-235b-4c75-8c08-a83bdaef3f66.eot?#iefix');
//   src:url($fonts-path + '6af9989e-235b-4c75-8c08-a83bdaef3f66.eot?#iefix') format('eot'),
//     url($fonts-path + 'd513e15e-8f35-4129-ad05-481815e52625.woff2') format('woff2'),
//     url($fonts-path + '61bd362e-7162-46bd-b67e-28f366c4afbe.woff') format('woff'),
//     url($fonts-path + 'ccd17c6b-e7ed-4b73-b0d2-76712a4ef46b.ttf') format('truetype'),
//     url($fonts-path + '20577853-40a7-4ada-a3fb-dd6e9392f401.svg#20577853-40a7-4ada-a3fb-dd6e9392f401') format('svg');
// }
// @font-face{
//   font-family:'Avenir LT W01_85 Heavy_1475550';
//   src:url($fonts-path + 'e6b412d9-d1ed-4b17-bb93-a6911df8640d.eot?#iefix');
//   src:url($fonts-path + 'e6b412d9-d1ed-4b17-bb93-a6911df8640d.eot?#iefix') format('eot'),
//     url($fonts-path + '3c210c80-960f-4684-850b-25390b4d08af.woff2') format('woff2'),
//     url($fonts-path + 'cb5c71ad-e582-4d00-929c-67fbfaeb1c27.woff') format('woff'),
//     url($fonts-path + '9ced8e96-4602-4507-8c20-4ff381949a9a.ttf') format('truetype'),
//     url($fonts-path + 'a7c1d112-7546-459d-a153-1467481298bd.svg#a7c1d112-7546-459d-a153-1467481298bd') format('svg');
// }
// @font-face{
//   font-family:'Avenir LT W01_95 Black1475556';
//   src:url($fonts-path + 'f55e4498-ad48-4577-93a0-c614de5cbbb8.eot?#iefix');
//   src:url($fonts-path + 'f55e4498-ad48-4577-93a0-c614de5cbbb8.eot?#iefix') format('eot'),
//     url($fonts-path + 'c78eb7af-a1c8-4892-974b-52379646fef4.woff2') format('woff2'),
//     url($fonts-path + '75b36c58-2a02-4057-a537-09af0832ae46.woff') format('woff'),
//     url($fonts-path + 'b5c44a82-eeb8-41de-9c3f-a8db50b24b8a.ttf') format('truetype'),
//     url($fonts-path + '93603a74-2be0-436c-83e1-68f9ef616eaf.svg#93603a74-2be0-436c-83e1-68f9ef616eaf') format('svg');
// }
// @font-face{
//   font-family:'Avenir LT W01_95 Black_1475562';
//   src:url($fonts-path + '4030caeb-d266-4d36-ac58-a3bb7a5b1bfa.eot?#iefix');
//   src:url($fonts-path + '4030caeb-d266-4d36-ac58-a3bb7a5b1bfa.eot?#iefix') format('eot'),
//     url($fonts-path + 'a2477e08-09d9-4d4b-97a9-23a1e22cb44c.woff2') format('woff2'),
//     url($fonts-path + '19d12bba-92b1-43ad-9bab-cd36a4195c2a.woff') format('woff'),
//     url($fonts-path + '5ba5a010-7470-4d9d-8a49-2920dc1be1f8.ttf') format('truetype'),
//     url($fonts-path + '6d4a6a8c-ef50-422f-8456-88d479e0b23d.svg#6d4a6a8c-ef50-422f-8456-88d479e0b23d') format('svg');
// }


// @font-face {
//   font-family: 'HarrietText';
//   src: url($fonts-path + 'HarrietText/' + 'a7d70fbb-f402-4a38-919d-6b90496faddc-2.eot');
//   src: url($fonts-path + 'HarrietText/' + 'a7d70fbb-f402-4a38-919d-6b90496faddc-2.eot?') format('embedded-opentype'),
//   url($fonts-path + 'HarrietText/' + 'a7d70fbb-f402-4a38-919d-6b90496faddc-3.woff') format('woff'),
//   url($fonts-path + 'HarrietText/' + 'a7d70fbb-f402-4a38-919d-6b90496faddc-1.ttf') format('truetype');
//   font-style: normal;
//   font-weight: bold;
// }

// @font-face {
//   font-family: 'Harriet Text Light';
//   src: url($fonts-path + 'HarrietText/' + 'a37551d5-ba1b-4576-b975-cf8004e66154-2.eot');
//   src: url($fonts-path + 'HarrietText/' + 'a37551d5-ba1b-4576-b975-cf8004e66154-2.eot?') format('embedded-opentype'),
//   url($fonts-path + 'HarrietText/' + 'a37551d5-ba1b-4576-b975-cf8004e66154-3.woff') format('woff'),
//   url($fonts-path + 'HarrietText/' + 'a37551d5-ba1b-4576-b975-cf8004e66154-1.ttf') format('truetype');
//   font-style: normal;
//   font-weight: normal;
// }
