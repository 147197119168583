
html, body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  font-family: $font-family-sans-serif;
}


.banner {
	flex-shrink: 0;
}

input {
	width: 100%;
	border: solid 1px #616161;
    margin-bottom: 8px;
    padding: 12px 8px 10px;
    font-size: 9px;
}

.btn.type2 {
	border-radius: 0;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 11px;
    height: 37px;
    background: #616161;
    color: #fff;
    width: 100%;
    padding: 11px 15px 10px;
}

.wrap {
  flex: 1 0 auto;
}

.content-footer {
  flex-shrink: 0;
  padding: 0rem 0.5rem;
  margin-bottom: 1.8rem;
  width: 100%;
  // border-top: 1px solid #d7d7d7;
  // border-bottom: 1px solid #d7d7d7;
  @include media-breakpoint-up(lg) {
      padding: 2rem 1rem;
  }
}

.no-gutter {
  padding-left: 0!important;
  padding-right: 0!important;
}

.flex-align-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.related-products {
  & div.lazy {
    height: 260px;
    @include media-breakpoint-up(lg) {
      height: 350px;
    }
  }
}


div.lazy {
    width: 100%;
    maxw-width: 260px;
    height: 260px;
    display: block;
    background-position: 50% 50%;
    background-size: cover;
   	background-repeat: no-repeat;
}

.lazy {
	opacity: 0;
	transition: all 0.35s;

		&.loaded {
			opacity: 1;

      &:hover {
        opacity: 0.7!important;
      }

		}
}


.leading-post {
  & .lazy {
    &.loaded {
      &:hover {
        opacity: 1!important;
      }
    }
  }
}


.lazy-outter {
	min-height: 200px;
	background-color: #f1f1f1;
}

.container a {
	color: #545454;
	text-decoration: none;
	transition: all 0.35s;

		&:hover {
			color: #333;
		}
}

.k-divider {
    height: 1px;
    background: #d7d7d7;
    margin-top: 2rem;
}

.font-secondary {
  font-family: $font-family-type3;
}

.font-medium {
  font-size: 1rem;
  line-height: 24px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  letter-spacing: 0.5px;
  font-family: $font-family-type2;
}

.hidden {
  display: none;
}

.visible-menu-xs {
  display: block;

  @include media-breakpoint-up(md) {
    display: none!important;
  }
}


.kassa {
    font-family: 'kassatex';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 20px;
    margin-right: 12px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.kassa-bag:before {
    content: "\e900"
}

.kassa-search:before {
    content: "\e901"
}

.kassa-bath:before {
    content: "\e902"
}

.kassa-kids:before {
    content: "\e903"
}

.kassa-bathrobe:before {
    content: "\e904"
}

.kassa-bedding:before {
    content: "\e905"
}

.kassa-accessory:before {
    content: "\e906"
}

.kassa-sale:before {
    content: "\e907"
}

.kassa-angle-left:before {
    content: "\e908"
}

.kassa-angle-up:before {
    content: "\e909"
}

.kassa-angle-down:before {
    content: "\e90a"
}

.kassa-angle-right:before {
    content: "\e90b"
}

.kassa-twitter:before {
    content: "\f099"
}

.kassa-facebook:before {
    content: "\f09a"
}

.kassa-pinterest:before {
    content: "\f0d2"
}

.kassa-instagram:before {
    content: "\f16d"
}
