// Grid system
.main {
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col($main-sm-columns);
    .sidebar-primary & {
      @include make-col($main-sm-columns - $sidebar-sm-columns);
    }
  }

  .vc_row {
    &.row {
      margin: 0!important;
    }
  }
}
.sidebar {
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col($sidebar-sm-columns);
  }
}


.container {
  width: 100%;
  padding-right: 7.5px;
  padding-left: 7.5px;
  margin-right: auto;
  margin-left: auto;
}


// Grid
.row {
  margin-right: -7.5px;
  margin-left: -7.5px;
}

[class^="col-"] {
  padding-left: 7.5px;
  padding-right: 7.5px;

  @include media-breakpoint-up(md) {
    padding-left: 15px;
    padding-right: 15px;
  }
}


.flex-row-kassatex {
  flex-direction: column;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}


.flex-row-kassatex-inner {
  padding: 0;
}


// Home Page
.home,
.page-template-template-home {
  & .container-h {
      padding: 0;
      width: 100%;
      max-width: 100%;
        & img {
            max-width: 100%;
            height: auto;
            min-height: 80px;
        }

        & > div {
            margin: 0;
              & .main {
                padding: 0;
              }
        }
  }
}
