// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

$fonts-path: '../fonts/';

//Different type of colors
$white: #fff;
$black: #000;

$color-type0: #505050;
$color-type1: $white;
$color-type2: #4d4d4d;
$color-type3: #dfdfda;
$color-type4: $color-type0;
$color-type5: #efeeeb;
$color-type6: #eef7f6;
$color-type7: #dcecf2;
$color-type8: #616161;
$color-type9: $white;
$color-type10: #505050;
$color-type11: #dddddd;
$color-type12: #bdbdbd;
$color-type13: #a9a99d;
$color-type14: #9c0003;
$color-type15: #c8c8c8;
$color-type16: #f9f9f7;

$link-color: $color-type0;

$font-family-type6: 'Effra', serif;
$font-family-type7: 'Effra', serif;

$font-family-type0: $font-family-type6;
$font-family-type2: $font-family-type7;

$font-family-type1: 'Effra', serif;
$font-family-type2i: 'Effra', Helvetica, Arial, Sans-serif;
$font-family-type4: $font-family-type7;

$font-family-type3: 'Vanitas', serif;

// $font-family-type5: 'Glyphicons Halflings';

//font family
$font-family-sans-serif:  $font-family-type7;
$font-family-serif: $font-family-type3;

$grid-gutter-width: 15px !default;
