// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap/scss/bootstrap.scss";
// endbower

@import "common/variables";
@import "common/fonts";
@import "common/offcanvas";


@import "common/global";
@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/grid";
@import "components/wp-classes";

@import "components/core";
@import "components/animate";
// @import "components/lazyload";


@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/tinymce";

.vc_row {
    &.row {
      margin: 0!important;
    }
}