.page-header {
	& h1 {
		font-size: 1.45rem;
		margin-bottom: 2rem;
	}
}


.archive {
	& .main {
		& .search-form {
			width: 100%;
		    max-width: 300px;
		    margin: 3rem auto;
		}

		& label,
		& input {
			width: 100%;
		}
	}
}

.wrap.container-h {
	margin-top: 1rem;

	@include media-breakpoint-up(sm) {
		margin-top: 0.5rem;
	}
}

.home {
	& article {
		text-align: center;
		padding-bottom: 0.75rem;
	}
}

.entry-title {
	font-weight: 400;
	font-family: $font-family-type3;
	font-size: 2rem;
	line-height: 1.2;
	margin-bottom: 1rem;
	text-transform: uppercase;

		&.leading-title {
			font-weight: 700;
			font-size: 1.6rem;
			line-height: 1.8rem;
			margin: 0.25rem 0 1rem;
		}
}

.leading-post {

	& figure {
		margin-bottom: 0;
	}

	& header {
		padding: 0;
	}

	& .entry-summary {
		margin-top: 1rem;
		padding: 0;

		& .leading-title a {
			color: #333;

			@include media-breakpoint-up(lg) {
				color: #fff!important;
			}
		}
	}
}


.single,
.archive {

	& .type-post {
		margin-top: 2rem;
	}

	& .entry-title {
		font-size: 1.65rem;
		line-height: 1.85rem;
		margin: 0.25rem 0 0.75rem;
	}

	& .post-gallery {
		margin-bottom: 1rem;
	}
}

.light-text-color {

	& .entry-category {
		& a {
			color: #fff;
			&:after {
				background-color: #fff;
			}
		}
	}

	& .entry-title,
	& .entry-excerpt,
	& .read-more {

		color: #fff!important;

		& a {
			color: #fff!important;
		}
	}
}

.entry-category {
	font-size: 0.95rem;
	margin: 0.75rem 0 1.25rem;
	text-transform: uppercase;
		& a {
			color: #646464;
			font-weight: 300;
	    font-size: 1.1rem;
	    letter-spacing: 2px;
			position: relative;

				&:after {
					content: "";
					position: absolute;
					height: 1px;
					background: #646464;
					width: 75%;
			    left: 0;
			    bottom: -8px;
			    right: 0;
			    margin: 0 auto;
				}
		}
}

.entry-content {
	& img {
		max-width: 100%;
		margin: 7px 0;

		@include media-breakpoint-up(md) {
			margin: 15px 0;
		}
	}

	[class^="col-"] {
	  & br,
	  & p:empty {
	  		display: none!important;
	  }

	  & h1,
	  & h2 {
	  	font-size: 2rem;
	  	margin-top: 0.5rem;

	  }

	  & h3,
	  & h4 {
	  	font-size: 1.5rem;
	  	line-height: 1.9rem;
	  	margin-bottom: 1.5rem;
	  }

	  & h2,
	  & h3, {
	  	margin: 2rem 0 0.5rem;
	  }
	}

	h1,
	  h2,
	  h3,
	  h4,
	  h5,
	  h6 {
	  	font-family: $font-family-type3!important;
	  }


	  h1.effra,
	  h2.effra,
	  h3.effra,
	  h4.effra,
	  h5.effra,
	  h6.effra {
  		font-family: $font-family-type1!important;
  		font-weight: 400!important;
  	}

}

.entry-content,
.entry-excerpt {
	font-size: 0.95rem;
	font-weight: 300;
    line-height: 1.9rem;
    font-family: $font-family-type7;
    	& strong,
    	& b {
    		font-weight: 500;
    		font-family: $font-family-type1;
    	}

    	& i,
    	& em {
    		font-weight: 400;
    		font-style: italic;
    		font-family: $font-family-type2i;
    	}
}

.entry-excerpt {
	font-size: 1rem;
    margin-bottom: 1rem;
    display: block;
    font-weight: 300;
    line-height: 1.3rem;
    padding: 0 0.225rem;
		letter-spacing: 1px;
}

.entry-caption {
	display: block;
    font-size: 0.7rem;
    line-height: 0.4rem;
    margin-bottom: 10px;
    letter-spacing: 0.1px;
    font-family: $font-family-type1;
}

.nav-links {
	& a,
	& span {
		display: inline-block;
	    float: left;
	    margin: 0 10px;
	}
}

.page-numbers {

	opacity: 0.5;

	&.current {
	    color: #000;
	    opacity: 1;
	}

	&:hover {
		opacity: 1;
	}
}



.post-gallery {

	margin: 0;

	& img {
		max-width: 100%;
		height: auto;
	}

	&:hover {
		& .lazy {
			opacity: 0.5;
		}
	}

	@include media-breakpoint-up(sm) {
		& .img-h310 {
			height: auto;
			min-height: 260px;
		}
	}

	@include media-breakpoint-up(md) {
		& .img-h310 {
			min-height: 200px;

			&.img-h310-lg {
				min-height: 62.5vh;
			}
		}


	}

	@include media-breakpoint-up(lg) {
		& .img-h310 {
			min-height: 450px;

			&.img-h310-lg {
				min-height: 66.5vh;
			}
		}
	}
}



.home {
	.entry-summary {
		padding: 1rem;
		@include media-breakpoint-up(lg) {
				height: 100%;
				padding: 3rem;
		}
	}

	.leading-post {
		.entry-summary {
			height: auto;
		}
	}
}

.rw-align-right {
	@include media-breakpoint-up(md) {
		header {
			order: 2
		}
	}
}

.read-more {
	display: block;
	font-size: 16px;
    text-transform: uppercase;
    margin-top: 0.775rem;
		letter-spacing: 1.65px;

    	&.read-more-lg {

    		font-size: 14px;
    		letter-spacing: 1px;

    			& .arrow {
    				font-size: 22px;
    			}
    	}
}


.single-entry-meta {
    text-transform: uppercase;
    color: #999;
    	& a {
    		color: #999;
    	}
}

.single-entry-meta {

	& a {
		margin-right: 5px;
	}

	& time.updated {
	    margin-left: 5px;
	}
}


.content-footer a {
	color: #000;
	font-size: 12px;
	font-family: $font-family-type2;
	text-transform: uppercase;
	letter-spacing: 1px;
	margin: 0 0 12px;
}

.subscription-block {
	& h4 {
			color: #fff;
	    font-size: 15px;
	    letter-spacing: 1px;
	    margin-bottom: 0.5rem;
	    text-align: center;
	    font-weight: 300;
	    text-transform: uppercase;
			@include media-breakpoint-up(lg) {
				font-size: 1.5rem;
			}
	}

	& input#k_id_email {
	    border: 0;
	    font-size: 0.9rem;
	    line-height: 1;
	    letter-spacing: 0.5px;
	    // border-bottom: 1px solid #545454;
	    font-family: $font-family-type2;
	}

	& button {
	    &.btn.type2 {
			line-height: 1;
			height: 40px;
	    // 	color: #545454;
		  //   font-size: 24px;
		  //   position: absolute;
		  //   line-height: 1;
		  //   padding: 0;
		  //   max-width: 40px;
		  //   right: 10px;
		  //   top: 0;
		  //   background: transparent;
	    }
	}
}


footer {
	& h3.section-title {
		font-size: 26px;
		margin: 2rem 0 1.5rem;
	    font-weight: normal;
	    font-family: $font-family-type3;
	}
}

.email-subscription-wrapper {
	background: #cdccca
}

.inner-subscription-block {
	padding: 2rem 0rem 1.5rem;
}

.social-sharing-single {
    margin-top: 3rem;
    max-width: 200px;
    margin: 3rem auto 0;

    	& span {
    		float: left;
    		margin-right: 10px;
    		margin-left: 25px;
    		font-family: $font-family-type2;
    	}

    	& .kass {
    		margin: 0 10px;
    	}
}


.content-tags {
	color: #999;
	margin: 2rem 0 1rem;

		& .k-tags {
			color: #999;
			letter-spacing: 1px;
			text-transform: uppercase;
			font-family: $font-family-type2;
		}

		& a {
			color: #999;
    		font-size: 1.1rem;
			font-family: $font-family-type2i;
		}
}


.related-products,
.related-story {

	& .product-price,
	& .product-title,
	& .post-title {
	    font-size: 16px;
	    margin-top: 0px;
	    font-weight: 700;
	    font-family: $font-family-type3;
	}

	& .product-price {
		color: #999;
		font-weight: 300;
		font-family: $font-family-type0;
	}

	& .entry-date {
	    display: block;
	    margin-top: 15px;
	    font-size: 14px;
	    color: #999;
	}
}

.related-products {
	margin-bottom: 0.5rem;

	& .product-title {
		margin-top: 15px;
		margin-bottom: 0;
		font-weight: 400;
		font-family: $font-family-type4;
	}
}


.section-title-related-products {
    margin: 2.85rem 0 2rem;
    padding-top: 1rem;
    font-size: 1rem;
    text-transform: uppercase;
    font-family: $font-family-type2;
    letter-spacing: 0.5px;
    position: relative;

    	&:before {
    		content: "";
		    position: absolute;
		    left: 0;
		    right: 0;
		    height: 1px;
		    background: #d7d7d7;
		    top: -1rem;
    	}
}


.single-pagination {
    padding: 1rem;
    border-top: 1px solid #d7d7d7;
    border-bottom: 1px solid #d7d7d7;
    margin: 2rem 0;
    display: block;
    	& a {
    		font-family: $font-family-type2;
    	}
}

.pagination {
	& span {
		display: block;
	}

	&.next-story {
	    float: right;
	    display: block;
	    width: 100%;
	}
}

.pagination-alpha:hover,
.pagination-omega:hover {
	cursor: pointer;
}

.pagination-alpha i {
	position: absolute;
    left: -12px;
    width: 25px;
    height: 25px;
    top: 50%;
    margin-top: -12px;
    cursor: pointer;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaWQ9IkxheWVyXzEiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ4IDQ4OyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNDggNDgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxnPjxwb2x5Z29uIHBvaW50cz0iMzAuOCw0NS43IDkuMSwyNCAzMC44LDIuMyAzMi4yLDMuNyAxMS45LDI0IDMyLjIsNDQuMyAgIi8+PC9nPjwvc3ZnPg==')
}
.pagination-omega i {
	position: absolute;
    right: -12px;
    width: 25px;
    height: 25px;
    top: 50%;
    margin-top: -12px;
    cursor: pointer;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaWQ9IkxheWVyXzEiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ4IDQ4OyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNDggNDgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxnPjxwb2x5Z29uIHBvaW50cz0iMTEuOCw0NS43IDEwLjQsNDQuMyAzMC44LDI0IDEwLjQsMy43IDExLjgsMi4zIDMzLjUsMjQgICIvPjwvZz48L3N2Zz4=');
}
